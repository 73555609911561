import { lighten } from 'polished'

const colors = {
  primary: '#003fff', // Color for buttons or links
  primaryLight: lighten(0.05, 'rgb(255, 220, 0)'),
  bg: 'rgb(0, 116, 217);', // Background color
  grey: {
    dark: 'rgba(255, 255, 255, 0.9)',
    default: 'rgba(255, 255, 255, 0.7)',
    light: 'rgba(255, 220, 0, 0.5)',
    ultraLight: 'rgb(0, 0, 0)',
  },
  white: 'white',
}

const transitions = {
  normal: '0.5s',
}

const fontSize = {
  small: '0.9rem',
}

const fontFamily = {
  serif: `'Bitter', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Helvetica', 'Arial', serif`,
  sansSerif: `-apple-system, "avenir next", avenir, roboto, noto, ubuntu, "helvetica neue", helvetica, arial, sans-serif`,
}

const breakpoints = {
  tablet: '1200px',
  phone: '600px',
}

const theme = {
  colors,
  transitions,
  fontSize,
  breakpoints,
  fontFamily,
  maxWidth: '1000px',
  baseFontSize: '18px',
}

export default theme
